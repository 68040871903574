import { Injectable } from "@angular/core";
import { MedNotification, MedNotificationService } from "@mk/med-notification";
import { MedLocalizationService } from "@mk/med-localization";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private notification: MedNotificationService,
    private localization: MedLocalizationService,
  ) {}

  success(key: string, config?: MedNotification): void {
    this.notification.success(key);
  }

  error(key: string, config?: MedNotification): void {
    this.notification.error(key);
  }

  info(key: string, config?: MedNotification): void {
    this.notification.info(this.localization.translate(key), config);
  }

  add(data: MedNotification): void {
    this.notification.add(data);
  }
}
