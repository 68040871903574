import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, repeat, throwError } from 'rxjs';
import { MedLocalizationService } from '@mk/med-localization';
import { ModalService } from '../services/modals.service';
import { AuthService } from '../api/auth.service';
import * as moment from 'moment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe((err) =>
      err.pipe(
        catchError((data) =>
          throwError((error: any) => {
            console.log(data);

            if (data.status === 401) {
              const tries = localStorage.getItem('amountOfTries');
              console.log(tries);
              const lastTryDate = localStorage.getItem('lastTryDate');
              if (
                lastTryDate &&
                moment(lastTryDate).add('1', 'minute').isAfter(moment())
              ) {
                let numTries = 0;
                if (tries && +tries) {
                  numTries = +tries;
                }
                numTries++;
                localStorage.setItem('amountOfTries', numTries.toString());
                localStorage.setItem('lastTryDate', new Date().toString());
              } else {
                localStorage.setItem('lastTryDate', new Date().toString());
                localStorage.setItem('amountOfTries', '0');
              }
              if (tries && +tries < 2) {
                console.log('here');
                if (data.status === 401) {
                  window.location.reload();
                }
              } else {
                // window.location.pathname = '/uk/error';
              }
            }

            if (data?.error?.type === 'translateError') {
              if (
                location.pathname.split('/')[1] === 'uk' &&
                location.pathname.split('/')[2] === 'media-center'
              ) {
                this.router.navigate(['/uk/media-center']);
              } else {
                this.modalService.setModal({
                  type: 'translate',
                  message: data?.error?.error,
                  action: data?.error?.action?.title,
                });
              }
            }

            return data;
          }),
        ),
      ),
    );
  }
}
