import { MedLocalizationSetupConfig } from "@mk/med-localization";

import {decomposeUrl} from "../utils/utils";
import {environment} from "../../environments/environment";

const [baseLocale] = decomposeUrl(location.pathname || "");

export const LOCALIZATION_CONFIG: MedLocalizationSetupConfig = {
  isDebug: environment.production,
  locale: baseLocale || environment.activeLocale,
  defaultLocale: environment.activeLocale,
  locales: environment.localizations,
  apiUrl: environment.localizationApi,
  ignoreLocales: environment.ignoreLocales,
  projectName: environment.localizationProjectName,
};
