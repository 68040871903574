<div *ngIf="loaded" class="contacts">
  <div class="contacts__wrapper block">
    <div class="contacts__left">
      <div class="contacts__title h1-heading">{{ contactsMain.title }}</div>
      <div class="contacts__info text">
        <div
          class="contacts__phone contacts__link"
          [innerHTML]="contactsMain.details"
        ></div>
      </div>
      <div class="contacts__items">
        <a
          [href]="item.link"
          [target]="'_blank'"
          pAnimateOnScroll
          enterClass="fadeindown"
          leaveClass="fadeoutdown"
          *ngFor="let item of contactsMain.links"
          class="contacts__item h9-text animation-duration-700"
          >{{ item.title }}
          <div class="icon" [inlineSVG]="item.img" alt=""></div>
        </a>
      </div>
      <div class="contacts__subtitle max h1-heading">
        {{ contactsMedia?.title }}
      </div>
      <div
        [innerHTML]="contactsMedia?.details"
        class="contacts__link text max"
      ></div>

      <div style="margin-top: 20px" class="contacts__subtitle max h1-heading">
        {{ contactsProzorro?.title }}
      </div>
      <div
        [innerHTML]="contactsProzorro?.details"
        class="contacts__link text max"
      ></div>

      <div style="margin-top: 20px" class="contacts__subtitle max h1-heading">
        {{ contactsSapAriba?.title }}
      </div>
      <div
        [innerHTML]="contactsSapAriba?.details"
        class="contacts__link text max"
      ></div>
    </div>
    <div class="contacts__right">
      <app-map
        [latitude]="contactsMain.coordinates.latitude"
        [longitude]="contactsMain.coordinates.longitude"
        [latitudePointer]="contactsMain.coordinates.latitude"
        [longitudePointer]="contactsMain.coordinates.longitude"
      ></app-map>
    </div>
    <div class="contacts__left-min">
      <div class="contacts__subtitle h1-heading">{{ contactsMedia.title }}</div>
      <div
        [innerHTML]="contactsMedia.details"
        class="contacts__link text"
      ></div>
      <div style="margin-top: 20px" class="contacts__subtitle  h1-heading">
        {{ contactsProzorro?.title }}
      </div>
      <div
        [innerHTML]="contactsProzorro?.details"
        class="contacts__link text "
      ></div>
      <div style="margin-top: 20px" class="contacts__subtitle  h1-heading">
        {{ contactsSapAriba?.title }}
      </div>
      <div
        [innerHTML]="contactsSapAriba?.details"
        class="contacts__link text "
      ></div>
    </div>
  </div>
  <div class="additional">
    <app-navigation-block
      *ngFor="let corruption of corruptions"
      [navLink]="corruption.actions[0].link"
      [title]="corruption.title"
      [actionTitle]="corruption.actions[0].title"
      [description]="
       corruption.details
      "
      [img]="corruption.img || './assets/images/main/news.jpeg'"
    ></app-navigation-block>
  </div>
</div>
