import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoadingSubject = new BehaviorSubject(false);
  isLoading$: Observable<boolean>;
  isGlobalLoadingSubject = new BehaviorSubject(true);
  isGlobalLoading$: Observable<boolean>;
  constructor() {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isGlobalLoading$ = this.isGlobalLoadingSubject.asObservable();
  }
}
