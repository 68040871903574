import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavigationBlockComponent} from "./navigation-block/navigation-block.component";
import {MedBtnModule} from "@mk/med-ui";
import {MedIconChevronRightModule} from "@mk/med-icons";
import {RouterLinkWithHref} from "@angular/router";
import {AnimateOnScrollModule} from "../directives/animate-on-scroll.directive";
import {ImageDirectiveModule} from "../directives/image.module";
import { VideoComponent } from './video/video.component';



@NgModule({
  declarations: [NavigationBlockComponent, VideoComponent],
    imports: [
        CommonModule,
        MedBtnModule,
        MedIconChevronRightModule,
        RouterLinkWithHref,
        AnimateOnScrollModule,
        ImageDirectiveModule
    ],
  exports: [
    NavigationBlockComponent,
    VideoComponent
  ]
})
export class SharedModule { }
