export enum APP_ROUTE_KEY {
  CONTACTS = 'CONTACTS',
  ABOUT = 'ABOUT',
  DKD = 'DKD',
  FILIA = 'FILIA',
  MAIN = 'MAIN',
  BI= 'BI',
  SUPPLIER = 'SUPPLIER',
  HOSPITALS = 'HOSPITALS',
  TEAM = 'TEAM',
  ERROR = 'ERROR',
  CAREER = 'CAREER',
  ORDERS = 'ORDERS',
  REPORTS = 'REPORTS',
  PATIENTS = 'PATIENTS',
  PUBLIC = 'PUBLIC',
  SUPPLIER_FAQ = 'SUPPLIER_FAQ',
  PATIENTS_FAQ = 'PATIENTS_FAQ',
  APPLY_REQUEST = 'APPLY_REQUEST',
  CATALOG = 'CATALOG',
  MEDIA = 'MEDIA',
  ANTICORRUPTION = 'ANTICORRUPTION',
  SEND_CORRUPTION = 'SEND_CORRUPTION',
  PROZORRO = 'PROZORRO',
  PUBLIC_ID = 'PUBLIC_ID',
  CAREER_ID = 'CAREER_ID',
  MEDIA_ID = 'MEDIA_ID',
  CATALOG_ID = 'CATALOG_ID',
  PROZORRO_ADD = 'PROZORRO_ADD',
  ACTIVE_CATEGORY = 'ACTIVE_CATEGORY',
  SEARCH = 'SEARCH'
}
