import { stringify } from 'qs';
import { STRING } from '../consts/string';

/**
 * /debug/ui-kit/form => ["debug", ["ui-kit", "form"]]
 */
export const decomposeUrl = (path: string): [string, string[]] => {
  const [lang, ...urls] = path.split(STRING.SLASH).filter(Boolean);
  return [lang, urls];
};

/**
 * '13.09.2022' => new Date
 */
export const parseDate = (date: string) =>
  new Date(date.split(STRING.DOT).reverse().join(STRING.DOT));

export const hasSomeValue = (arr1: string[], arr2: string[]): boolean =>
  arr1.some((role) => arr2.includes(role));

/**
 * "2022-11-29T12:35:39.986444700Z" => 'Tue Nov 29 2022' => 1669672800000
 */
export const getDateWithoutTime = (dateStr: string): number =>
  +new Date(new Date(dateStr).toDateString());

export const convertQuery = (query: Record<string, any> = {}): string => {
  const params = stringify(query, {
    allowDots: true,
  });

  return params ? `?${params}` : STRING.EMPTY;
};

export const parseHtml = (html: string) => {
  html = replaceAll(html, '&gt;', '>');
  html = replaceAll(html, '&lt;', '<');
  html = replaceAll(html, '\n', '<br>');
  return html;
};

function replaceAll(string: string, search: string, replace: string) {
  return string.split(search).join(replace);
}

export function transliterate(word: any) {
  var answer = '',
    a: any = {};

  a['Ё'] = 'YO';
  a['Й'] = 'I';
  a['Ц'] = 'TS';
  a['У'] = 'U';
  a['К'] = 'K';
  a['Е'] = 'E';
  a['Н'] = 'N';
  a['Г'] = 'G';
  a['Ш'] = 'SH';
  a['Щ'] = 'SCH';
  a['З'] = 'Z';
  a['Х'] = 'H';
  a['Ъ'] = "'";
  a['ё'] = 'yo';
  a['й'] = 'i';
  a['ц'] = 'ts';
  a['у'] = 'u';
  a['к'] = 'k';
  a['е'] = 'e';
  a['н'] = 'n';
  a['г'] = 'g';
  a['ш'] = 'sh';
  a['щ'] = 'sch';
  a['з'] = 'z';
  a['х'] = 'h';
  a['ъ'] = "'";
  a['Ф'] = 'F';
  a['Ы'] = 'I';
  a['В'] = 'V';
  a['А'] = 'A';
  a['П'] = 'P';
  a['Р'] = 'R';
  a['О'] = 'O';
  a['Л'] = 'L';
  a['Д'] = 'D';
  a['Ж'] = 'ZH';
  a['Э'] = 'E';
  a['ф'] = 'f';
  a['ы'] = 'i';
  a['в'] = 'v';
  a['а'] = 'a';
  a['п'] = 'p';
  a['р'] = 'r';
  a['о'] = 'o';
  a['л'] = 'l';
  a['д'] = 'd';
  a['ж'] = 'zh';
  a['э'] = 'e';
  a['Я'] = 'Ya';
  a['Ч'] = 'CH';
  a['С'] = 'S';
  a['М'] = 'M';
  a['И'] = 'I';
  a['Т'] = 'T';
  a['Ь'] = "'";
  a['Б'] = 'B';
  a['Ю'] = 'YU';
  a['я'] = 'ya';
  a['ч'] = 'ch';
  a['с'] = 's';
  a['м'] = 'm';
  a['и'] = 'i';
  a['т'] = 't';
  a['ь'] = "'";
  a['б'] = 'b';
  a['ю'] = 'yu';

  for (let i in word) {
    if (word.hasOwnProperty(i)) {
      if (a[word[i]] === undefined) {
        answer += word[i];
      } else {
        answer += a[word[i]];
      }
    }
  }
  return answer;
}
