<a
  [routerLink]="
        '/' + localization.locale + '/suppliers/public/' + publicItem.id
      "
  class="public-item block"
>
  <div class="public-item__header">
    <div *ngIf="publicItem.number" class="public-item__header-action text-small img-div">
      {{ publicItem.number }}
    </div>
    <div *ngIf="publicItem.idNumber" class="public-item__header-action text-small img-div">
      {{ publicItem.idNumber }}
    </div>
  </div>
  <div class="public-item__content h5-heading">
    {{ publicItem.title }}
  </div>
</a>
