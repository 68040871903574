import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuppliersComponent } from './suppliers.component';
import {
  MedIconCalendarModule,
  MedIconChevronRightModule,
  MedIconPlusModule,
} from '@mk/med-icons';
import { MedBtnModule } from '@mk/med-ui';
import { SuppliersRoutingModule } from './suppliers-routing.module';
import { SupplierPublicComponent } from '../supplier-public/supplier-public.component';
import { PaginationModule } from '../../../shared/pagination/pagination.module';
import { FaqModule } from '../../../shared/faq/faq.module';
import { AnimateOnScrollModule } from '../../../directives/animate-on-scroll.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { PublicItemComponent } from '../supplier-public/public-item/public-item.component';
import {TooltipModule} from "primeng/tooltip";

@NgModule({
  declarations: [
    SuppliersComponent,
    SupplierPublicComponent,
    PublicItemComponent,
  ],
  exports: [SuppliersComponent, SupplierPublicComponent, PublicItemComponent],
    imports: [
        CommonModule,
        MedIconChevronRightModule,
        SuppliersRoutingModule,
        MedBtnModule,
        MedIconCalendarModule,
        PaginationModule,
        FaqModule,
        AnimateOnScrollModule,
        MedIconPlusModule,
        TranslocoModule,
        TooltipModule,
    ],
})
export class SuppliersModule {}
