import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent {
  @Input()
  width: string = '580px';
  @Input()
  height: string = '580px';

  @Input()
  latitude = 52.520008;
  @Input()
  longitude = 13.404954;

  @Input()
  latitudePointer = 52.520008;
  @Input()
  longitudePointer = 13.404954;


  @Input()
  titleZoomIn = 'Zoom in';
  @Input()
  titleZoomOut = 'Zoom out';

  @Input()
  titleCurrentLocation = 'Current location';

  @Input()
  opacity = 1;
  @Input()
  zoom = 16;

  position: any;

  constructor() {}

  increaseZoom() {
    this.zoom++;
  }
  decreaseZoom() {
    this.zoom--;
  }
}
