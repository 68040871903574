import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadCrumbService {
  private detailLinkSubject = new BehaviorSubject('');
  constructor() {}

  setLink(link: string): void {
    this.detailLinkSubject.next(link);
  }

  getLink(): Observable<string> {
    return this.detailLinkSubject.asObservable();
  }
}
