<header class="header">
  <div class="header__wrapper">
    <a [routerLink]="localization.locale + ''" class="header__logo">
      <div [inlineSVG]="'assets/images/logo.svg'"></div>
      <span class="text">{{ "header.mpu" | transloco }}</span>
    </a>
    <nav class="header__menu menu">
      <ul class="menu__items">
        <ng-template #menuItemTemplate let-items>
          <li
            [routerLinkActiveOptions]="{ exact: item.level === 1 }"
            [routerLinkActive]="'menu__item_active'"
            class="menu__item"
            (click)="navigate($event)"
            [routerLink]="localization.locale + '/' + item.link"
            *ngFor="let item of items"
          >
            <a class="h9-text">{{ item.title | transloco }}</a>
            <med-icon-chevron-down
              [size]="'16px'"
              *ngIf="item.children && item.level === 0"
            ></med-icon-chevron-down>
            <div class="hover-icon">
              <med-icon-chevron-right
                [size]="'16px'"
                *ngIf="item.level === 1"
              ></med-icon-chevron-right>
            </div>
            <ng-container *ngIf="item.children">
              <ul
                class="menu__sub-menu"
                [ngClass]="'sub-menu-type-' + item.level"
              >
                <ng-container
                  *ngTemplateOutlet="
                    menuItemTemplate;
                    context: { $implicit: item.children }
                  "
                >
                </ng-container>
              </ul>
            </ng-container>
          </li>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            menuItemTemplate;
            context: { $implicit: menuItems }
          "
        ></ng-container>
      </ul>
    </nav>
    <div class="menu-min">
      <med-icon-menu
        style="cursor: pointer"
        *ngIf="!isSidebarOpen"
        (click)="isSidebarOpen = true"
      ></med-icon-menu>
      <med-icon-close
        style="cursor: pointer"
        *ngIf="isSidebarOpen"
        (click)="isSidebarOpen = false"
      ></med-icon-close>
    </div>
    <div
      [ngClass]="{ 'sidebar-menu_open': isSidebarOpen }"
      class="sidebar-menu block"
    >
      <div [formGroup]="searchForm" class="sidebar-search">
        <med-search-bar
          (search)="search()"
          (keydown.enter)="search()"
          (ngModelChange)="searchEmpty($event)"
          [style]="'width:100%'"
          [formControlName]="'search'"
          [placeholder]="'search.title' | transloco"
        ></med-search-bar>
      </div>
      <ul class="sidebar__items">
        <ng-template #minMenuItemTemplate let-items>
          <li
            [routerLinkActiveOptions]="{ exact: item.level === 1 }"
            [routerLinkActive]="'sidebar__item_active'"
            class="sidebar__item h9-text"
            (click)="navigate($event)"
            [ngClass]="{ sidebar__item_active: item.active }"
            [routerLink]="
              item.level === 1 ? localization.locale + '/' + item.link : null
            "
            *ngFor="let item of items"
          >
            <div
              (click)="selectItem(items, item)"
              class="sidebar__item-wrapper"
            >
              <a class="h9-text">{{ item.title | transloco }}</a>
              <med-icon-chevron-down
                [size]="'16px'"
                *ngIf="item.children && item.level === 0 && !item.active"
              ></med-icon-chevron-down>
              <med-icon-chevron-up
                [size]="'16px'"
                *ngIf="item.children && item.level === 0 && item.active"
              ></med-icon-chevron-up>
              <div class="hover-icon">
                <med-icon-chevron-right
                  [size]="'16px'"
                  *ngIf="item.level === 1"
                ></med-icon-chevron-right>
              </div>
            </div>
            <ng-container *ngIf="item.children">
              <ul
                class="sidebar__sub-menu"
                [ngClass]="'sub-menu-type-' + item.level"
              >
                <ng-container
                  *ngTemplateOutlet="
                    minMenuItemTemplate;
                    context: { $implicit: item.children }
                  "
                >
                </ng-container>
              </ul>
            </ng-container>
          </li>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            minMenuItemTemplate;
            context: { $implicit: menuItems }
          "
        ></ng-container>
      </ul>
      <div class="header__actions min-actions">
        <div class="header__actions-language">
          <med-select
            class="base-layout__locale"
            [hasErrorPadding]="false"
            [hideClearBtn]="true"
            [options]="locales"
            [value]="localization.locale"
            (onInput)="setLocale($event)"
          ></med-select>
        </div>
        <div class="header__actions-blind">
          <med-btn
            [state]="isBetterView ? 'focus' : ''"
            (click)="toggleBetterView()"
            skin="gray"
          >
            <div
              [style]="isBetterView ? '' : 'margin-right: 10px'"
              class="icon"
              [inlineSVG]="'assets/images/eye.svg'"
            ></div>
            <span [ngClass]="{ 'hidden-text': isBetterView }">{{
              "header.visual-impairment" | transloco
              }}</span>
            <!--        -->

            <!--       -->
          </med-btn>
          <ng-container *ngIf="isBetterView">
            <med-btn
              [style]="!viewSize ? 'pointer-events: none' : ''"
              (click)="lowerView()"
              [disabled]="!viewSize"
              class="square-padding h8-text"
              [skin]="'gray'"
            >
              A -
            </med-btn>
            <med-btn
              [disabled]="viewSize === 3"
              (click)="nextView()"
              class="square-padding h8-text"
              [skin]="'gray'"
            >
              A +
            </med-btn>
          </ng-container>
        </div>
        <a
          [target]="'_blank'"
          [href]="'https://mpu.ethics.help'"
          class="header__actions-complience"
        >
          <med-btn class="normal-padding">
        <span class="header__actions-complience_big">{{
          "header.compliance-control" | transloco
          }}</span>
            <span class="header__actions-complience_small">Complience</span>
            <med-icon-plus
              class="icon"
              style="margin-left: 10px"
              [size]="'16px'"
            ></med-icon-plus>
          </med-btn>
        </a>
      </div>
    </div>
  </div>
  <div class="header__actions">
    <div
      (click)="emitSearch($event)"
      class="header__actions-search"
      [ngClass]="{ 'header__actions-search_active': isSearch }"
    >
      <med-icon-search></med-icon-search>
      <div
        (click)="preventDefault($event)"
        [ngClass]="{ 'header__actions-search-bar-wrapper_active': isSearch }"
        class="header__actions-search-bar-wrapper"
      >
        <div class="label h4-heading">{{'search.title' | transloco}}</div>
        <div
          [formGroup]="searchForm"
          class="header__actions-search-bar">
          <med-search-bar
            (search)="search()"
            (keydown.enter)="search()"
            (ngModelChange)="searchEmpty($event)"
            [style]="'width:100%'"
            [formControlName]="'search'"
            [placeholder]="'search.title' | transloco"
          ></med-search-bar>
          <med-btn (click)="search(); preventDefault($event)" class="search-padding">{{'search.searchBtn' | transloco}}</med-btn>
        </div>
      </div>
    </div>
    <div class="header__actions-language">
      <med-select
        class="base-layout__locale"
        [hasErrorPadding]="false"
        [hideClearBtn]="true"
        [options]="locales"
        [value]="localization.locale"
        (onInput)="setLocale($event)"
      ></med-select>
    </div>
    <div class="header__actions-blind">
      <med-btn
        [state]="isBetterView ? 'focus' : ''"
        (click)="toggleBetterView()"
        skin="gray"
      >
        <div
          [style]="isBetterView ? '' : 'margin-right: 10px'"
          class="icon"
          [inlineSVG]="'assets/images/eye.svg'"
        ></div>
        <span [ngClass]="{ 'hidden-text': isBetterView }">{{
          "header.visual-impairment" | transloco
        }}</span>
        <!--        -->

        <!--       -->
      </med-btn>
      <ng-container *ngIf="isBetterView">
        <med-btn
          [style]="!viewSize ? 'pointer-events: none' : ''"
          (click)="lowerView()"
          [disabled]="!viewSize"
          class="square-padding h8-text"
          [skin]="'gray'"
        >
          A -
        </med-btn>
        <med-btn
          [disabled]="viewSize === 3"
          (click)="nextView()"
          class="square-padding h8-text"
          [skin]="'gray'"
        >
          A +
        </med-btn>
      </ng-container>
    </div>
    <a
      [target]="'_blank'"
      [href]="'https://mpu.ethics.help'"
      class="header__actions-complience"
    >
      <med-btn class="normal-padding">
        <span class="header__actions-complience_big">{{
          "header.compliance-control" | transloco
        }}</span>
        <span class="header__actions-complience_small">Complience</span>
        <med-icon-plus
          class="icon"
          style="margin-left: 10px"
          [size]="'16px'"
        ></med-icon-plus>
      </med-btn>
    </a>
  </div>
</header>
