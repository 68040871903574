import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class BrowserDetectionService {
  constructor(private deviceService: DeviceDetectorService) {}

  isSupportedBrowser(): boolean {
    const browserInfo = this.deviceService.getDeviceInfo();
    const browserName = browserInfo.browser;
    const browserVersion = parseInt(browserInfo.browser_version, 10);

    const supportedBrowsers = {
      'Chrome': 80,
      'Firefox': 84,
      'Safari': 14,
      'Opera': 66,
      'Edge': 88
    } as any;
    return supportedBrowsers[browserName] ? browserVersion >= supportedBrowsers[browserName] : false;
  }
}
