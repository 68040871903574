import { Component, Input, OnInit } from '@angular/core';
import { ItemApiService } from '../../../api/item-api.service';
import { MedLocalizationService } from '@mk/med-localization';

@Component({
  selector: 'app-supplier-public',
  templateUrl: './supplier-public.component.html',
  styleUrls: ['./supplier-public.component.scss'],
})
export class SupplierPublicComponent implements OnInit {
  constructor(public localization: MedLocalizationService) {}

  @Input() publicItems: any[] = [];

  ngOnInit(): void {}
}
