import { Component, OnInit } from '@angular/core';
import { mainBlockMock } from '../../consts/mainBlockMock.const';
import { MedLocalizationService } from '@mk/med-localization';
import { ItemApiService } from '../../api/item-api.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../services/loader.service';
import { delay } from 'rxjs';
import {ImageService} from "../../services/image.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [ImageService]
})
export class MainComponent implements OnInit {
  mainBlocks: any[] = [];
  environment = environment;
  constructor(
    public localization: MedLocalizationService,
    private loaderService: LoaderService,
    private itemService: ItemApiService,
    public imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.loaderService.isLoadingSubject.next(true);
    this.itemService.getMain().subscribe(
      (data: any[]) => {
        this.mainBlocks = data.sort((a, b) => a.position - b.position);
        this.loaderService.isLoadingSubject.next(false);
      },
      () => {
        this.loaderService.isLoadingSubject.next(false);
      },
    );
  }
}
