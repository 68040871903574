export const STRING = {
  DOT: ".",
  DASH: "–",
  MINUS: "-",
  EMPTY: "",
  COMMA: ",",
  SPACE: " ",
  SLASH: "/",
  QUOTE: "'",
  QUESTION: "?",
  DOUBLE_QUOTES: "\"",
  DELIMITER: {
    HYPHEN_WITH_SPACE: " – ",
    COMMA: ", ",
  },
} as const;
