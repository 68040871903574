import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MedLocalizationFilePath } from '@mk/med-localization';
import { APP_ROUTES } from './APP_ROUTES';
import { LocalizeRoutes } from '../services/LocalizeRoutes';
import { MainComponent } from '../core/main/main.component';

const LOCALIZED_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: MainComponent,
  },
  {
    path: APP_ROUTES.CONTACTS.path,
    data: APP_ROUTES.CONTACTS.data,
    loadChildren: () =>
      import('../features/contacts/contacts.module').then(
        (m) => m.ContactsModule,
      ),
  },
  {
    path: APP_ROUTES.ABOUT.path,
    data: APP_ROUTES.ABOUT.data,
    loadChildren: () =>
      import('../features/about/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: APP_ROUTES.FILIA.path,
    data: APP_ROUTES.FILIA.data,
    loadChildren: () =>
      import('../features/about/about-filia/about-filia.module').then(
        (m) => m.AboutFiliaModule,
      ),
  },
  {
    path: APP_ROUTES.TEAM.path,
    data: APP_ROUTES.TEAM.data,
    loadChildren: () =>
      import('../features/about/team/team.module').then((m) => m.TeamModule),
  },
  {
    path: APP_ROUTES.SUPPLIER.path,
    data: APP_ROUTES.SUPPLIER.data,
    loadChildren: () =>
      import('../features/suppliers/suppliers/suppliers.module').then(
        (m) => m.SuppliersModule,
      ),
  },
  {
    path: APP_ROUTES.HOSPITALS.path,
    data: APP_ROUTES.HOSPITALS.data,
    loadChildren: () =>
      import('../features/hospitals/hospitals/hospitals.module').then(
        (m) => m.HospitalsModule,
      ),
  },
  {
    path: APP_ROUTES.REPORTS.path,
    data: APP_ROUTES.REPORTS.data,
    loadChildren: () =>
      import('../features/about/reports/reports.module').then(
        (m) => m.ReportsModule,
      ),
  },
  {
    path: APP_ROUTES.ORDERS.path,
    data: APP_ROUTES.ORDERS.data,
    loadChildren: () =>
      import('../features/about/orders/orders.module').then(
        (m) => m.OrdersModule,
      ),
  },
  {
    path: APP_ROUTES.CAREER.path,
    data: APP_ROUTES.CAREER.data,
    loadChildren: () =>
      import('../features/about/career/career.module').then(
        (m) => m.CareerModule,
      ),
  },
  {
    path: APP_ROUTES.BI.path,
    data: APP_ROUTES.BI.data,
    loadChildren: () =>
      import('../features/bi/bi.module').then(
        (m) => m.BiModule,
      ),
  },
  {
    path: APP_ROUTES.BI_SUPPLIER.path,
    data: APP_ROUTES.BI_SUPPLIER.data,
    loadChildren: () =>
      import('../features/bi-supplier/bi-supplier.module').then(
        (m) => m.BiSupplierModule,
      ),
  },
  {
    path: APP_ROUTES.HOSPITALS_SCHEDULE.path,
    data: APP_ROUTES.HOSPITALS_SCHEDULE.data,
    loadChildren: () =>
      import('../features/hospitals-schedule/hospital-schedule.module').then(
        (m) => m.HospitalScheduleModule,
      ),
  },
  {
    path: APP_ROUTES.PATIENTS.path,
    data: APP_ROUTES.PATIENTS.data,
    loadChildren: () =>
      import('../features/patients/patients/patients.module').then(
        (m) => m.PatientsModule,
      ),
  },
  {
    path: APP_ROUTES.PUBLIC_ID.path,
    data: APP_ROUTES.PUBLIC_ID.data,
    loadChildren: () =>
      import('../features/suppliers/public-details/public-details.module').then(
        (m) => m.PublicDetailsModule,
      ),
  },
  {
    path: APP_ROUTES.CAREER_ID.path,
    data: APP_ROUTES.CAREER_ID.data,
    loadChildren: () =>
      import('../features/about/career-detail/career-detail.module').then(
        (m) => m.CareerDetailModule,
      ),
  },
  {
    path: APP_ROUTES.MEDIA_ID.path,
    data: APP_ROUTES.MEDIA_ID.data,
    loadChildren: () =>
      import('../features/media/media-detail/media-detail.module').then(
        (m) => m.MediaDetailModule,
      ),
  },
  {
    path: APP_ROUTES.CATALOG_ID.path,
    data: APP_ROUTES.CATALOG_ID.data,
    loadChildren: () =>
      import('../features/patients/catalog-detail/catalog-detail.module').then(
        (m) => m.CatalogDetailModule,
      ),
  },
  {
    path: APP_ROUTES.PUBLIC.path,
    data: APP_ROUTES.PUBLIC.data,
    loadChildren: () =>
      import('../features/suppliers/public/public.module').then(
        (m) => m.PublicModule,
      ),
  },

  {
    path: APP_ROUTES.SUPPLIER_FAQ.path,
    data: APP_ROUTES.SUPPLIER_FAQ.data,
    loadChildren: () =>
      import('../features/suppliers/suppliers-faq/suppliers-faq.module').then(
        (m) => m.SuppliersFaqModule,
      ),
  },
  {
    path: APP_ROUTES.PATIENTS_FAQ.path,
    data: APP_ROUTES.PATIENTS_FAQ.data,
    loadChildren: () =>
      import('../features/patients/patients-faq/patients-faq.module').then(
        (m) => m.PatientsFaqModule,
      ),
  },
  {
    path: APP_ROUTES.APPLY_REQUEST.path,
    data: APP_ROUTES.APPLY_REQUEST.data,
    loadChildren: () =>
      import('../features/patients/apply-request/apply-request.module').then(
        (m) => m.ApplyRequestModule,
      ),
  },
  {
    path: APP_ROUTES.CATALOG.path,
    data: APP_ROUTES.CATALOG.data,
    loadChildren: () =>
      import('../features/patients/catalog/catalog.module').then(
        (m) => m.CatalogModule,
      ),
  },
  {
    path: APP_ROUTES.MEDIA.path,
    data: APP_ROUTES.MEDIA.data,
    loadChildren: () =>
      import('../features/media/media/media.module').then((m) => m.MediaModule),
  },
  {
    path: APP_ROUTES.ANTICORRUPTION.path,
    data: APP_ROUTES.ANTICORRUPTION.data,
    loadChildren: () =>
      import(
        '../features/anticorruption/anticorruption/anticorruption.module'
      ).then((m) => m.AnticorruptionModule),
  },
  {
    path: APP_ROUTES.SEND_CORRUPTION.path,
    data: APP_ROUTES.SEND_CORRUPTION.data,
    loadChildren: () =>
      import(
        '../features/anticorruption/send-corruption/send-corruption.module'
      ).then((m) => m.SendCorruptionModule),
  },
  {
    path: APP_ROUTES.PROZORRO.path,
    data: APP_ROUTES.PROZORRO.data,
    loadChildren: () =>
      import('../features/prozorro/prozorro/prozorro.module').then(
        (m) => m.ProzorroModule,
      ),
  },
  {
    path: APP_ROUTES.PROZORRO_ADD.path,
    data: APP_ROUTES.PROZORRO_ADD.data,
    loadChildren: () =>
      import('../features/prozorro/add-product/add-product.module').then(
        (m) => m.AddProductModule,
      ),
  },

  {
    path: APP_ROUTES.ACTIVE_CATEGORY.path,
    data: APP_ROUTES.ACTIVE_CATEGORY.data,
    loadChildren: () =>
      import('../features/prozorro/active-categories/active-categories.module').then(
        (m) => m.ActiveCategoriesModule,
      ),
  },
  {
    path: APP_ROUTES.SEARCH.path,
    data: APP_ROUTES.SEARCH.data,
    loadChildren: () =>
      import('../features/search/search.module').then(
        (m) => m.SearchModule,
      ),
  },
  {
    path: APP_ROUTES.DKD.path,
    data: APP_ROUTES.DKD.data,
    loadChildren: () =>
      import('../features/dkd/meas/meas.module').then(
        (m) => m.MeasModule,
      ),
  },
  {
    path: APP_ROUTES.ERROR.path,
    data: APP_ROUTES.ERROR.data,
    loadChildren: () =>
      import('../features/error/error.module').then(
        (m) => m.ErrorModule,
      ),
  },
];

const DEFAULT_LOCALIZATION_FILES: MedLocalizationFilePath[] = [];

const localizeRoutes = new LocalizeRoutes(
  LOCALIZED_ROUTES,
  [],
  DEFAULT_LOCALIZATION_FILES,
);

@NgModule({
  imports: [RouterModule.forRoot(localizeRoutes.build())],
  exports: [RouterModule],
})
export class AppRoutingModule {}
