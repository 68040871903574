/**
 * @dynamic is for runtime initializing DomHandler.browser
 *
 * If delete below comment, we can see this error message:
 *  Metadata collected contains an error that will be reported at runtime:
 *  Only initialized variables and constants can be referenced
 *  because the value of this variable is needed by the template compiler.
 */
// @dynamic
export class DomHandler {
  public static zindex: number = 1000;

  private static calculatedScrollbarWidth: any = null;

  private static calculatedScrollbarHeight: any = null;

  private static browser: any;

  public static addClass(element: any, className: string): void {
    if (element && className) {
      if (element.classList) element.classList.add(className);
      else element.className += ' ' + className;
    }
  }

  public static addMultipleClasses(element: any, className: string): void {
    if (element && className) {
      if (element.classList) {
        let styles: string[] = className.trim().split(' ');
        for (let i = 0; i < styles.length; i++) {
          element.classList.add(styles[i]);
        }
      } else {
        let styles: string[] = className.split(' ');
        for (let i = 0; i < styles.length; i++) {
          element.className += ' ' + styles[i];
        }
      }
    }
  }

  public static removeClass(element: any, className: string): void {
    if (element && className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi',
          ),
          ' ',
        );
    }
  }

  public static removeMultipleClasses(element: any, classNames: any) {
    if (element && classNames) {
      [classNames]
        .flat()
        .filter(Boolean)
        .forEach((cNames) =>
          cNames
            .split(' ')
            .forEach((className: any) => this.removeClass(element, className)),
        );
    }
  }

  public static hasClass(element: any, className: string): boolean {
    if (element && className) {
      if (element.classList) return element.classList.contains(className);
      else
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(
          element.className,
        );
    }

    return false;
  }
}
