<aol-map style="position: relative" [width]="width" [height]="height">
  <aol-interaction-default></aol-interaction-default>
  <aol-view class="map" [zoom]="zoom">
    <aol-coordinate [x]="longitude" [y]="latitude" [srid]="'EPSG:4326'"></aol-coordinate>
  </aol-view>
  <aol-layer-tile>
    <aol-source-osm></aol-source-osm>
  </aol-layer-tile>
  <aol-layer-vector [opacity]="opacity">
    <aol-source-vector>
      <aol-feature>
        <aol-geometry-point>
          <aol-coordinate
            [x]="longitudePointer"
            [y]="latitudePointer"
            [srid]="'EPSG:4326'"
          ></aol-coordinate>
        </aol-geometry-point>
      </aol-feature>
      <aol-feature>
        <aol-geometry-point>
          <aol-coordinate
            [x]="longitudePointer"
            [y]="latitudePointer"
            [srid]="'EPSG:4326'"
          ></aol-coordinate>
        </aol-geometry-point>
        <aol-style>
          <aol-style-icon
            [src]="'assets/images/marker.svg'"
            [anchor]="[0.5, 1]"
            [anchorXUnits]="'fraction'"
            [anchorYUnits]="'fraction'"
            [scale]="1.3"
            [anchorOrigin]="'top-left'"
          >
          </aol-style-icon>
        </aol-style>
      </aol-feature>
    </aol-source-vector>
  </aol-layer-vector>
  <aol-control>
    <aol-content>
      <div class="ol-overlaycontainer-stopevent">
        <div class="ol-zoom ol-unselectable ol-control">
          <button
            [title]="titleZoomIn"
            class="ol-zoom-in"
            type="button"
            title="Zoom in"
            (click)="increaseZoom()"
          >
            +
          </button>
          <button
            [title]="titleZoomOut"
            class="ol-zoom-out"
            type="button"
            title="Zoom out"
            (click)="decreaseZoom()"
          >
            −
          </button>
        </div>
      </div>
    </aol-content>
  </aol-control>
</aol-map>

