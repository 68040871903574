import { Component, OnInit } from '@angular/core';
import { ItemApiService } from '../../../api/item-api.service';
import { parseHtml } from '../../../utils/utils';
import { forkJoin } from 'rxjs';
import { LoaderService } from '../../../services/loader.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  loaded = false;
  contactsMain: any;
  contactsMedia: any;
  contactsSapAriba: any;
  contactsProzorro: any;
  corruptions: any;
  constructor(
    private itemService: ItemApiService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.isLoadingSubject.next(true);
  }

  ngOnInit(): void {
    forkJoin([
      this.itemService.getContacts(),
      this.itemService.newsCareer(),
    ]).subscribe(([contactsData, corruptions]) => {
      this.contactsMain = contactsData.contactsMain;
      this.contactsMedia = contactsData.contactsMedia;
      this.contactsSapAriba = contactsData.contactsSapAriba;
      this.contactsSapAriba.details = parseHtml(contactsData.contactsSapAriba.details);
      this.contactsProzorro = contactsData.contactsProzorro;
      this.corruptions = corruptions;
      this.corruptions[1].actions[0].link =
        '/media-center/' +
        this.corruptions[1].id;
      this.contactsMain.details = parseHtml(this.contactsMain.details);
      this.contactsMedia.details = parseHtml(this.contactsMedia.details);
      this.contactsProzorro.details = parseHtml(this.contactsProzorro.details);
      this.loaded = true;
      this.loaderService.isLoadingSubject.next(false);
    }, ()=>{
      this.loaded = true;
      this.loaderService.isLoadingSubject.next(false);
    });
  }
}
