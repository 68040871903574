<div
  pAnimateOnScroll
  enterClass="fadein"
  leaveClass="fadeout"
  class="main animation-duration-500"
  [ngStyle]="{ opacity: (imageService.imagesLoading$ | async) ? '0' : '1' }"
>
  <ng-container *ngFor="let elem of mainBlocks" [ngSwitch]="elem.type">
    <a
      [routerLink]="elem?.actions?.[0]?.link"
      class="simple-block block"
      *ngSwitchCase="'imageButton'"
    >
      <img imgDirective [src]="elem.img" class="simple-block__img" />
      <div class="simple-block__title h5-heading">
        <span> {{ elem.title }}</span>
        <med-icon-chevron-right></med-icon-chevron-right>
      </div>
    </a>
    <div
      [routerLink]="'/' + localization.locale + '/' + elem?.actions?.[0]?.link"
      class="image-with-bg-block block"
      *ngSwitchCase="'imageWithBg'"
    >
      <div class="image-with-bg-block__img-wrapper">
        <img
          imgDirective
          [src]="'assets/images/main/bg.png'"
          class="image-with-bg-block__img-bg"
        />
        <img imgDirective [src]="elem.img" class="image-with-bg-block__img" />
      </div>
      <a class="image-with-bg-block__title h5-heading">
        {{ elem.title }}
        <med-icon-chevron-right></med-icon-chevron-right>
      </a>
    </div>
    <div
      [style]="{
        'background-image':
          'url(' + (elem.img || './assets/images/main/news.jpeg') + ')'
      }"
      class="news-block block img-div"
      *ngSwitchCase="'news'"
    >
      <div class="backdrop"></div>
      <div class="news-block__wrapper">
        <div class="news-block__top">
          <div class="news-block__title h5-heading">
            {{ elem.title }}
          </div>
          <div class="news-block__subtitle text">
            {{ elem.details }}
          </div>
        </div>
        <div class="news-block__bottom">
          <div class="news-block__actions">
            <a
              *ngFor="let action of elem.actions; let index = index"
              [routerLink]="
                index
                  ? action.link
                  : '/' + localization.locale + '/media-center/' + elem.id
              "
            >
              <med-btn class="news-block__action"
                >{{ action.title }}
                <div class="action-icon">
                  <med-icon-chevron-right
                    [size]="'16px'"
                  ></med-icon-chevron-right>
                </div>
              </med-btn>
            </a>
          </div>
        </div>
      </div>
    </div>
    <a
      [href]="elem?.actions?.['0']?.link"
      [target]="'_blank'"
      class="image-and-text-block block"
      *ngSwitchCase="'imageAndText'"
    >
      <img imgDirective [src]="elem.img" class="image-and-text-block__img" />
      <div class="image-and-text-block__title h2-heading">
        {{ elem.title }}
      </div>
      <div class="image-and-text-block__subtitle h8-text">
        {{ elem.details }}
      </div>
      <div class="image-and-text-block__midtitle h7-heading">
        {{ elem.actions?.[0]?.title }}
        <div class="action-icon">
          <med-icon-chevron-right
            [size]="'16px'"
          ></med-icon-chevron-right>
        </div>
      </div>
    </a>
    <div class="only-text-block block" *ngSwitchCase="'onlyText'">
      <div class="only-text-block__title h1-heading">
        {{ elem.title }}
      </div>
      <div class="only-text-block__subtitle h8-text">
        {{ elem.details }}
      </div>
      <div class="only-text-block__actions">
        <a *ngFor="let action of elem.actions" [routerLink]="action.link">
          <med-btn class="only-text-block__action">{{ action.title }}</med-btn>
        </a>
      </div>
    </div>
  </ng-container>
</div>
<div class="loader" *ngIf="imageService.imagesLoading$ | async">
  <div class="loader__icon">
    <img
      src="/assets/images/loading-line.svg"
      class="loader__progress"
      alt="loading"
    />

    <img src="/assets/images/herb.svg" alt="Herb" />
  </div>
</div>
