import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MedPaginationModule,
  MedRadioGroupModule,
} from "@mk/med-ui";

import { PaginationComponent } from "./pagination.component";
import {TranslocoModule} from "@ngneat/transloco";

@NgModule({
  declarations: [
    PaginationComponent,
  ],
    imports: [
        CommonModule,
        MedPaginationModule,
        MedRadioGroupModule,
        TranslocoModule,
    ],
  exports: [
    PaginationComponent,
  ],
})
export class PaginationModule { }
