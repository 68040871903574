import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsComponent } from './contacts/contacts.component';
import {ContactsRoutingModuleRoutingModule} from "./contacts-routing.module";
import {SharedModule} from "../../shared/shared.module";
import { MapComponent } from './contacts/map/map.component';
import {AngularOpenlayersModule} from "ngx-openlayers";
import {InlineSVGModule} from "ng-inline-svg-2";
import {AnimateOnScrollModule} from "../../directives/animate-on-scroll.directive";



@NgModule({
    declarations: [
        ContactsComponent,
        MapComponent
    ],
    exports: [
        ContactsComponent,
        MapComponent
    ],
    imports: [
        ContactsRoutingModuleRoutingModule,
        CommonModule,
        SharedModule,
        AngularOpenlayersModule,
        InlineSVGModule,
        AnimateOnScrollModule
    ]
})
export class ContactsModule { }
