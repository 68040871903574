<div class="flex flex-center flex-between">
  <med-pagination
    [total]="total"
    [page]="page"
    [perPage]="limit"
    (pageChange)="pageChange.emit($event)"
  ></med-pagination>
  <div class="wrapper">
    <div class="total h10-text">
      {{ "pagination.limit" | transloco }}
    </div>
    <med-radio-group
      mode="buttons"
      [value]="limit"
      [options]="paginationOptions"
      (onInput)="limitChange.emit($event)"
    ></med-radio-group>
  </div>
</div>
