<div class="container">
  <div class="top" [ngClass]="{'no-overflow': loaderService.isLoading$ | async}">
    <app-header *ngIf="authService.currentUserValue"></app-header>
    <app-bread-crumbs></app-bread-crumbs>
    <router-outlet *ngIf="authService.currentUserValue"></router-outlet>
  </div>
  <div class="bottom">
    <app-footer *ngIf="authService.currentUserValue"></app-footer>
  </div>

  <div
    [ngClass]="{ 'main-loader': router.url.split('/').length < 3 }"
    class="loader"
    *ngIf="loaderService.isLoading$ | async"
  >
    <div class="loader__icon">
      <img
        src="/assets/images/loading-line.svg"
        class="loader__progress"
        alt="loading"
      />

      <img src="/assets/images/herb.svg" alt="Herb" />
    </div>
  </div>
</div>
<app-loader
  *ngIf="loaderService.isGlobalLoading$ | async"
  [global]="true"
></app-loader>

<med-modal
  [showBackdrop]="true"
  [(visible)]="langModal"
  [closeableOutside]="false"
>
  <div class="language-modal-wrapper">
    <div
      class="language-modal-wrapper__title h2-heading"
      [innerHTML]="langText"
    ></div>

    <div class="language-modal-wrapper__action">
      <med-btn (click)="back()" class="normal-padding">
        <med-icon-arrow-left [size]="'25px'"></med-icon-arrow-left>
        <span class="h6-heading" style="color: #fff; margin-left: 10px"
          >Back</span
        >
      </med-btn>
      <med-btn (click)="changeLanguage()" class="normal-padding">
        <span class="h6-heading" style="color: #fff; margin-right: 10px">{{
          langBtnText ? langBtnText : 'Switch to Ukrainian'
        }}</span>
        <med-icon-arrow-right [size]="'25px'"></med-icon-arrow-right>
      </med-btn>
    </div>
  </div>
</med-modal>

<med-modal
  [closeableOutside]="true"
  [showBackdrop]="true"
  [(visible)]="showPopup"
>
  <div (click)="closePopup()" class="modal-action">
    <med-icon-x size="16px"></med-icon-x>
  </div>
  <div class="language-modal-wrapper browser-modal-wrapper">
    <div class="language-modal-wrapper__title h2-heading">
      {{ "browser.title" | transloco }}
    </div>

    <div class="language-modal-wrapper__description h4-heading">
      {{ "browser.description" | transloco }}
    </div>
  </div>
</med-modal>
<med-notification></med-notification>
