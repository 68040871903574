<div class="supplier" *ngIf="loaded">
  <div class="supplier__main block">
    <div
      pAnimateOnScroll
      enterClass="fadein"
      leaveClass="fadeout"
      class="supplier__main-text animation-duration-1000"
    >
      <div class="supplier__main-title h1-heading">
        {{ suppliersMain.title }}
      </div>
      <div
        class="supplier__main-description text"
        [innerHTML]="suppliersMain.details"
      ></div>
      <div
        pAnimateOnScroll
        enterClass="fadeindown"
        leaveClass="fadeoutdown"
        class="supplier__main-actions animation-duration-500"
      >
        <a
          [tooltipStyleClass]="'mpu-tooltip'"
          [pTooltip]="action.tooltip"
          [tooltipPosition]="getTooltipPosition()"
          (click)="scrollToElement($event, index, target)"
          *ngFor="let action of suppliersMain.actions; let index = index"
          [attr.href]="index === 3 ? ('/' + localization.locale + '/' + action.link) : action.link"
          [target]="index === 3 ? '_self' : '_blank'"
        >
          <ng-container *ngIf="index === 0; else others">
            <med-btn class="normal-padding">
              {{ action.title }}
            </med-btn>
          </ng-container>
          <ng-template #others>
            <button class="supplier__main-action action-button">
              {{ action.title }}
            </button>
          </ng-template>
        </a>
      </div>
    </div>
    <div
      pAnimateOnScroll
      enterClass="fadein"
      leaveClass="fadeout"
      class="supplier__main-img animation-duration-1000"
    >
      <img [src]="suppliersMain.img" />
    </div>
    <div
      pAnimateOnScroll
      enterClass="fadein"
      leaveClass="fadeout"
      class="supplier__main-img-min"
    >
      <img [src]="suppliersMain.img" />
    </div>
  </div>
  <div class="supplier__items">
    <div
      class="supplier__item_packing supplier__item block animation-duration-500"
    >
      <div class="supplier__item_packing-img">
        <img [src]="howWeBuy.img" alt="" />
      </div>
      <div class="supplier__item-title h2-heading">{{ howWeBuy.title }}</div>
    </div>
    <a
      [href]="item.actions[0].link"
      [target]="'_blank'"
      *ngFor="let item of $any(howWeBuy.content)"
      class="supplier__item block animation-duration-500 min"
    >
      <div class="supplier__item-img">
        <img [src]="item.img" alt="" />
      </div>
      <div class="supplier__item-info">
        <div class="supplier__item-info-title h4-heading">
          {{ item.title }}
          <med-icon-chevron-right
            class="min-icon"
            [size]="'16px'"
          ></med-icon-chevron-right>
        </div>
        <div class="supplier__item-info-description text" [innerHTML]="item.details">
        </div>
        <div class="supplier__item-info-actions">
          <a
            class="animation-duration-700"
            pAnimateOnScroll
            enterClass="fadeindown"
            leaveClass="fadeoutdown"
            *ngFor="let action of item.actions; let index = index"
            [target]="'_blank'"
            [href]="action.link"
          >
            <ng-container *ngIf="index === 0; else others">
              <med-btn class="normal-padding">
                {{ action.title }}
                <med-icon-chevron-right
                  style="margin-left: 10px"
                  [size]="'16px'"
                ></med-icon-chevron-right
              ></med-btn>
            </ng-container>
            <ng-template #others>
              <button class="action-button">
                {{ action.title }}
              </button>
            </ng-template>
          </a>
        </div>
      </div>
    </a>
    <div
      *ngFor="let item of $any(howWeBuy.content)"
      class="supplier__item block animation-duration-500 max"
    >
      <div class="supplier__item-img">
        <img [src]="item.img" alt="" />
      </div>
      <div class="supplier__item-info">
        <div class="supplier__item-info-title h4-heading">
          {{ item.title }}
          <med-icon-chevron-right
            class="min-icon"
            [size]="'16px'"
          ></med-icon-chevron-right>
        </div>
        <div class="supplier__item-info-description text" [innerHTML]="item.details">
        </div>
        <div class="supplier__item-info-actions">
          <a
            class="animation-duration-700"
            pAnimateOnScroll
            enterClass="fadeindown"
            leaveClass="fadeoutdown"
            *ngFor="let action of item.actions; let index = index"
            [target]="'_blank'"
            [href]="action.link"
          >
            <ng-container *ngIf="index === 0; else others">
              <med-btn class="normal-padding">
                {{ action.title }}
                <med-icon-chevron-right
                  style="margin-left: 10px"
                  [size]="'16px'"
                ></med-icon-chevron-right
              ></med-btn>
            </ng-container>
            <ng-template #others>
              <button class="action-button">
                {{ action.title }}
              </button>
            </ng-template>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="supplier__tender block">
    <div
      *ngIf="tenderProcedures"
      class="supplier__tender-top animation-duration-500"
      pAnimateOnScroll
      enterClass="fadeindown"
      leaveClass="fadeoutdown"
    >
      <div class="supplier__tender-top-wrapper">
        <img
          class="supplier__tender-top-img"
          src="assets/images/supplier__basket.svg"
          alt=""
        />
        <div class="supplier__tender-top-title h2-heading">
          {{ tenderProcedures.title }}
        </div>
      </div>
      <div
        class="supplier__tender-top-description text"
        [innerHTML]="tenderProcedures.description"
      ></div>
    </div>
    <div *ngIf="tenderProcedures" class="supplier__tender-middle">
      <div
        *ngFor="let tender of $any(tenderProcedures.content)"
        class="supplier__tender-middle-item img-div img-div-white animation-duration-500"
        pAnimateOnScroll
        enterClass="fadeindown"
        leaveClass="fadeoutdown"
      >
        <div class="supplier__tender-middle-item-title text-big">
          {{ tender.title }}
        </div>
        <div
          class="supplier__tender-middle-item-text"
          [innerHTML]="tender.details"
        ></div>
      </div>
    </div>
    <div class="supplier__tender-bottom" #target>
      <div
        class="supplier__tender-bottom-left animation-duration-500"
        pAnimateOnScroll
        enterClass="fadeinleftease"
        leaveClass="fadeoutleftease"
      >
        <div class="supplier__tender-bottom-left-title h7-heading img-div">
          {{ whatWeBuy.title }}
        </div>
        <div
          class="supplier__tender-bottom-left-description h8-text"
          [innerHTML]="whatWeBuy.description"
        ></div>
      </div>
      <div class="supplier__tender-bottom-right">
        <div
          class="supplier__tender-bottom-right-items animation-duration-700"
          pAnimateOnScroll
          enterClass="fadeinrightease"
          leaveClass="fadeoutrightease"
        >
          <a
            [ngClass]="{ 'no-link': !item?.actions?.[0]?.link }"
            [target]="'_blank'"
            [href]="item?.actions?.[0]?.link"
            *ngFor="let item of $any(supplyItems)"
            class="supplier__tender-bottom-right-item"
          >
            <span class="h9-text">{{ item.title }}</span>
          </a>
          <med-btn
            (click)="addSupplies()"
            *ngIf="supplyTotalPage > supplyItemPage"
            class="normal-padding"
            skin="gray"
            >{{ "faq.more" | transloco }}</med-btn
          >
          <med-btn
            (click)="hide()"
            *ngIf="supplyTotalPage <= supplyItemPage && supplyItemPage > 0"
            class="normal-padding"
            skin="gray"
            >{{ "faq.hide" | transloco }}</med-btn
          >
        </div>
      </div>
    </div>
  </div>
  <div class="supplier__public">
    <div class="supplier__public-header">
      <div
        [ngClass]="publicItems.error ? 'pointer-none' : ''"
        [routerLink]="
          publicItems.error
            ? null
            : '/' + localization.locale + '/suppliers/public'
        "
        class="supplier__public-header-title h4-heading"
      >
        {{ publicItems.title }}
        <med-icon-chevron-right
          *ngIf="!publicItems.error"
          class="min-icon"
        ></med-icon-chevron-right>
      </div>
      <med-btn
        *ngIf="!publicItems.error"
        [routerLink]="'/' + localization.locale + '/suppliers/public'"
        skin="gray"
        class="normal-padding supplier__public-action"
        >{{ publicItems.actions.title }}
        <med-icon-chevron-right
          style="margin-left: 10px"
          [size]="'16px'"
        ></med-icon-chevron-right>
      </med-btn>
    </div>
    <app-supplier-public
      *ngIf="!publicItems.error; else error"
      pAnimateOnScroll
      enterClass="fadein"
      class="animation-duration-2000"
      leaveClass="fadeout"
      [publicItems]="publicItems.content"
    ></app-supplier-public>
    <ng-template #error>
      <div
        style="margin-bottom: 16px"
        class="supplier__public-error h5-heading"
      >
        {{ publicItems.error }}
      </div>
    </ng-template>
  </div>

  <app-faq
    *ngIf="questions.content?.length"
    [icon]="questions.img"
    [title]="questions.title"
    [link]="'faq'"
    class="faq-block"
    [questions]="questions.content"
  ></app-faq>
</div>
