<div class="about" *ngIf="loaded">
  <div class="block about__main">
    <div
      pAnimateOnScroll
      enterClass="fadein"
      leaveClass="fadeout"
      class="about__main-left animation-duration-1000"
    >
      <div class="about__main-title h1-heading">
        {{ aboutMain.title }}
      </div>
      <div class="about__main-text-wrapper">
        <div class="about__main-text-inner">
          <div
            [innerHTML]="aboutMain.details"
            class="about__main-subtitle h6-heading"
          >
          </div>
          <div
            [innerHTML]="aboutMain.description"
            class="about__main-text text"
          ></div>
          <div
            class="about__infos animation-duration-500"
            pAnimateOnScroll
            enterClass="fadeindown"
            leaveClass="fadeoutdown"
          >
            <a
              [routerLink]="'/' + lanaguage + '/' + action.link"
              *ngFor="let action of aboutMain.actions"
            >
              <button class="about__infos-item action-button">
                {{ action.title }}
              </button>
            </a>
          </div>
        </div>
        <img class="about__img-small" [src]="aboutMain.img" alt="" />
      </div>
    </div>
    <div
      pAnimateOnScroll
      enterClass="fadein"
      leaveClass="fadeout"
      class="animation-duration-1000"
    >
      <img
        pAnimateOnScroll
        enterClass="fadein"
        leaveClass="fadeout"
        imgDirective
        class="about__img animation-duration-500"
        [src]="aboutMain.img"
        alt=""
      />
    </div>
  </div>
  <div class="block about__details">
    <div
      class="about__details-left animation-duration-1000"
      pAnimateOnScroll
      enterClass="fadein"
      leaveClass="fadeout"
    >
      <p class="about__details-text text" [innerHTML]="aboutInfo.details"></p>
    </div>
    <div
      pAnimateOnScroll
      enterClass="fadein"
      leaveClass="fadeout"
      class="about__details-img animation-duration-1000"
    >
      <img imgDirective [src]="aboutInfo.img" />
    </div>
  </div>
  <div class="about__goals block-min">
    <div class="about__goals-left block animation-duration-500">
      <div class="about__goals-title_left img-div h7-heading">
        {{ aboutMissons.misson.title }}
      </div>
      <div class="about__goals-text h5-heading">
        {{ aboutMissons.misson.details }}
      </div>
    </div>
    <div class="about__goals-border"></div>
    <div class="about__goals-right block animation-duration-500">
      <div class="about__goals-title_right img-div h7-heading">
        {{ aboutMissons.vision.title }}
      </div>
      <div class="about__goals-text h5-heading">
        {{ aboutMissons.vision.details }}
      </div>
    </div>
  </div>
  <div class="block about__values">
    <div class="about__values-wrapper">
      <div
        class="about__values-left animation-duration-1000"
        pAnimateOnScroll
        enterClass="fadeinleftease"
        leaveClass="fadeoutleftease"
      >
        <div class="about__values-title img-div h7-heading">
          {{ aboutValues.title }}
        </div>
        <div class="about__values-text-items">
          <div
            *ngFor="let text of aboutValues.texts"
            class="about__values-text-item"
          >
            <div class="about__values-text-title h5-heading">
              {{ text.title }}
            </div>
            <div class="about__values-text h8-text">
              {{ text.details }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="about__values-right animation-duration-1000"
        pAnimateOnScroll
        enterClass="fadeinrightease"
        leaveClass="fadeoutrightease"
      >
        <div class="about__values-img">
          <div class="about__values-circle">
            <img
              class="about__values-circle-bg"
              src="assets/images/about__big-ellips.png"
              alt=""
            />
            <div class="about__values-group">
              <img [src]="aboutValues.img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about__values-items">
      <a
        class="about__values-item animation-duration-1000 img-div"
        *ngFor="let item of aboutValues.items; let index = index"
        pAnimateOnScroll
        enterClass="fadein"
        [ngClass]="item.actions ? 'c-pointer' : 'c-init'"
        [target]="'_blank'"
        (click)="prevent(!!item.actions, $event)"
        [href]="item.actions ? item.actions[0].link : null"
        leaveClass="fadeout"
      >
        <div class="about__values-item-wrapper">
          <img [src]="item.img" alt="" />
          <div class="about__values-item-title h1-heading">
            {{ item.title }}
          </div>
        </div>
        <div class="about__values-item-text h8-text">
          {{ item.details }}
        </div>
      </a>
    </div>
  </div>
  <div class="about__values about__values-min">
    <div class="about__values-items">
      <a
        class="about__values-item animation-duration-1000 img-div"
        pAnimateOnScroll
        [ngClass]="item.actions ? 'c-pointer' : 'c-init'"
        [target]="'_blank'"
        (click)="prevent(!!item.actions, $event)"
        [href]="item.actions ? item.actions[0].link : null"
        enterClass="fadein"
        leaveClass="fadeout"
        *ngFor="let item of aboutValues.items; let index = index"
      >
        <div class="about__values-item-wrapper">
          <img [src]="item.img" alt="" />
          <div class="about__values-item-title h1-heading">
            {{ item.title }}
          </div>
        </div>
        <div class="about__values-item-text h8-text">
          {{ item.details }}
        </div>
      </a>
    </div>
  </div>
  <div class="about__partners">
    <div
      class="about__partners-left animation-duration-500"
      pAnimateOnScroll
      enterClass="fadeinleftease"
      leaveClass="fadeoutleftease"
    >
      <div class="about__partners-img">
        <img [src]="aboutPartners.img" alt="" />
      </div>
      <div class="about__partners-title h2-heading">
        {{ aboutPartners.title }}
      </div>
    </div>
    <div
      class="about__partners-right animation-duration-500"
      pAnimateOnScroll
      enterClass="fadeinrightease"
      leaveClass="fadeoutrightease"
    >
      <div class="about__partners-items">
        <a
          [href]="partner.link"
          [target]="'_blank'"
          *ngFor="let partner of aboutPartners['content']"
          class="block about__partners-item"
        >
          <img class="about__partners-item-img" [src]="partner.img" alt="" />
        </a>
      </div>
    </div>
  </div>
  <div class="additional">
    <app-navigation-block
      *ngFor="let corruption of corruptions"
      [navLink]="corruption.actions[0].link"
      [title]="corruption.title"
      [actionTitle]="corruption.actions[0].title"
      [description]="corruption.details"
      [img]="corruption.img"
    ></app-navigation-block>
  </div>
</div>
