import {Component, Input, OnInit} from '@angular/core';
import {MedLocalizationService} from "@mk/med-localization";
import {ImageService} from "../../services/image.service";

@Component({
  selector: 'app-navigation-block',
  templateUrl: './navigation-block.component.html',
  styleUrls: ['./navigation-block.component.scss']
})
export class NavigationBlockComponent implements OnInit {

  @Input() title: string = '';
  @Input() img: string = '';
  @Input() external = false;
  @Input() extraClass = '';
  @Input() description: string = '';
  @Input() actionTitle: string = '';
  @Input() navLink: string = '';
  constructor(
    public localization: MedLocalizationService,
  ) { }

  ngOnInit(): void {
  }

}
