import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalSubject = new BehaviorSubject( { type: '', message: '', action: '' });
  constructor() {}

  setModal({ type, message, action }: { type: string; message: string, action: string }): void {
    this.modalSubject.next({ type, message, action });
  }

  getModal(): Observable<{ type: string; message: string, action: string }> {
    return this.modalSubject.asObservable();
  }
}
