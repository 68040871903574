import {APP_INITIALIZER, isDevMode, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { MainComponent } from './core/main/main.component';
import { ContactsModule } from './features/contacts/contacts.module';
import { SuppliersModule } from './features/suppliers/suppliers/suppliers.module';
import {
    MedIconActionsModule, MedIconArrowLeftModule, MedIconArrowRightModule,
    MedIconChevronDownModule,
    MedIconChevronRightModule,
    MedIconChevronUpModule,
    MedIconCloseModule,
    MedIconDotModule,
    MedIconMenuModule,
    MedIconPlusModule,
    MedIconSearchModule, MedIconWarningModule, MedIconXModule,
} from '@mk/med-icons';
import {
  MedBtnModule,
  MedInputModule, MedModalModule,
  MedSearchBarModule,
  MedSelectModule,
} from '@mk/med-ui';
import { MedLocalizationModule } from '@mk/med-localization';
import { AppRoutingModule } from './router/app-routing.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AboutModule } from './features/about/about/about.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { ScrollService } from './services/scroll.service';
import {
  AnimateOnScrollModule,
} from './directives/animate-on-scroll.directive';
import { LoaderModule } from './shared/loader/loader.module';
import { MyImgDirective } from './directives/image-directive';
import {ImageDirectiveModule} from "./directives/image.module";
import {BreadCrumbsComponent} from "./core/bread-crumbs/bread-crumbs.component";
import {NoopAnimationsModule} from "@angular/platform-browser/animations";
import {ErrorInterceptor} from "./http-interceptors/error-interceptor";
import {MedNotificationModule} from "@mk/med-notification";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthService} from "./api/auth.service";
import {JwtInterceptor} from "./http-interceptors/jwt.interceptor";

export function initializeApp(authService: AuthService) {
  return () => authService.login();
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    BreadCrumbsComponent,
    HeaderComponent,
    MainComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NoopAnimationsModule,
        ContactsModule,
        SuppliersModule,
        InlineSVGModule,
        MedIconChevronDownModule,
        MedIconSearchModule,
        MedLocalizationModule,
        MedSelectModule,
        MedIconChevronRightModule,
        MedBtnModule,
        MedIconPlusModule,
        MedIconMenuModule,
        MedIconDotModule,
        MedIconActionsModule,
        AboutModule,
        HttpClientModule,
        TranslocoRootModule,
        MedInputModule,
        MedSearchBarModule,
        MedIconChevronUpModule,
        AnimateOnScrollModule,
        MedIconCloseModule,
        LoaderModule,
        ImageDirectiveModule,
        MedModalModule,
        MedIconWarningModule,
        MedIconArrowRightModule,
        MedNotificationModule,
        FormsModule,
        MedIconArrowLeftModule,
        MedIconXModule,
        ReactiveFormsModule,
    ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'uk-UK',
    },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    ScrollService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor(private scrollService: ScrollService) {}
}
