import { Injectable } from '@angular/core';
import { Observable, catchError, of, map } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCoreService {
  constructor(
    private http: HttpClient,
    private notification: NotificationService,
  ) {}

  private _headers = new HttpHeaders();
  // .set("Authorization", `Bearer ${this.state.token}`);

  get<ResponseType>(
    url: string,
    params?: Record<string, any>,
    headers?: any,
  ): Observable<ResponseType> {
    const options = { params, headers: headers || this._headers };
    return this.http.get<any>(url, options).pipe(
      map((el) => {
        if (el.success === false) {
          // if (el.error) {
          //   this.notification.errorText(el.error);
          // } else {
          //   this.notification.errorText('Помилка при завантаженні даних');
          // }
          throw { error: 'getError' };
        }
        return el;
      }),
    );
  }

  post<ResponseType>(
    url: string,
    params?: Record<string, any>,
  ): Observable<ResponseType> {
    return this.http.post<any>(url, params, { headers: this._headers }).pipe(
      map((el) => {
        if (el.success === false) {
          // this.notification.errorText(el.error);
          throw { error: 'postError' };
        }
        return el;
      }),
    );
  }

  patch<ResponseType>(
    url: string,
    params?: Record<string, any>,
  ): Observable<ResponseType> {
    return this.http.patch<any>(url, params, { headers: this._headers }).pipe(
      map((el) => {
        if (el.success === false) {
          // this.notification.errorText(el.error);
          throw { error: 'postError' };
        }
        return el;
      }),
    );
  }

  getBlob(url: string, params?: Record<string, any>): Observable<Blob> {
    return this.http.request('GET', url, {
      params,
      headers: this._headers,
      responseType: 'blob',
    });
  }

  put<ResponseType>(
    url: string,
    params?: Record<string, any>,
  ): Observable<ResponseType> {
    return this.http.put<any>(url, params, { headers: this._headers }).pipe(
      map((el) => {
        if (el.success === false) {
          // this.notification.errorText(el.error);
          throw { error: 'postError' };
        }
        return el;
      }),
    );
  }

  delete<ResponseType>(
    url: string,
    params?: Record<string, any>,
  ): Observable<ResponseType> {
    const options = { params, headers: this._headers };
    return this.http.delete<ResponseType>(url, options);
  }
}
