import {Component, Input, OnInit} from '@angular/core';
import {MedLocalizationService} from "@mk/med-localization";

@Component({
  selector: 'app-public-item',
  templateUrl: './public-item.component.html',
  styleUrls: ['./public-item.component.scss']
})
export class PublicItemComponent implements OnInit {

  @Input() publicItem: any;
  constructor(public localization: MedLocalizationService) { }

  ngOnInit(): void {
  }

}
