import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq.component';
import {MedAccordionModule, MedBtnModule, MedSelectModule} from "@mk/med-ui";
import {MedIconChevronRightModule} from "@mk/med-icons";
import {FormsModule} from "@angular/forms";
import {RouterLinkWithHref} from "@angular/router";
import {AnimateOnScrollModule} from "../../directives/animate-on-scroll.directive";
import {TranslocoModule} from "@ngneat/transloco";
import {SpinnerModule} from "primeng/spinner";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {LoaderModule} from "../loader/loader.module";



@NgModule({
  declarations: [
    FaqComponent
  ],
  exports: [
    FaqComponent
  ],
  imports: [
    CommonModule,
    MedBtnModule,
    MedIconChevronRightModule,
    MedAccordionModule,
    MedSelectModule,
    FormsModule,
    RouterLinkWithHref,
    AnimateOnScrollModule,
    TranslocoModule,
    SpinnerModule,
    ProgressSpinnerModule,
    LoaderModule
  ]
})
export class FaqModule { }
