import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnChanges {
  @Input() videoUrl: string = '';
  safeUrl!: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}
  sanitizeUrl(url: string): SafeResourceUrl {
    let embedUrl = url;
    if (url.includes('youtube')) {
      const videoId = url.split('v=')[1];
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        embedUrl = `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`;
      } else {
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (url.includes('vimeo')) {
      const videoId = url.split('.com/')[1];
      embedUrl = `https://player.vimeo.com/video/${videoId}`;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

  ngOnChanges(): void {
    this.safeUrl = this.sanitizeUrl(this.videoUrl);
  }
}
