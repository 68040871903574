<div [ngClass]="global ? 'global-loader' : 'loader'">
  <div [ngClass]="global ? 'global-loader__icon' : 'loader__icon'">
    <img
      src="/assets/images/loading-line.svg"
      [ngClass]="global ? 'global-loader__progress' : 'loader__progress'"
      alt="loading"
    />

    <img
      src="/assets/images/herb.svg"
      alt="Herb"
    />
  </div>
</div>
