import { Component, OnInit } from '@angular/core';
import { LOCALIZATION_CONFIG } from './configs/localization.config';
import { MedLocalizationService } from '@mk/med-localization';
import { LoaderService } from './services/loader.service';
import { Location } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { ModalService } from './services/modals.service';
import { filter } from 'rxjs';
import { parseHtml } from './utils/utils';
import { Router } from '@angular/router';
import { AuthService } from './api/auth.service';
import { environment } from '../environments/environment';
import { keys } from 'lodash';
import { BrowserDetectionService } from './services/browser-detection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'mpu-website';
  langText = '';
  showPopup: boolean = false;
  langBtnText = '';
  langModal = false;
  constructor(
    private _location: Location,
    public router: Router,
    public authService: AuthService,
    private browserDetectionService: BrowserDetectionService,
    private modalService: ModalService,
    public localization: MedLocalizationService,
    public translocoService: TranslocoService,
    public loaderService: LoaderService,
  ) {
    this.localization.setFilesList([]);
    this.localization.init(LOCALIZATION_CONFIG);
    this.translocoService.setActiveLang(LOCALIZATION_CONFIG.locale as string);

    const bw = localStorage.getItem('betterView') === 'true';
    if (bw) {
      document.getElementById('body')?.classList.add('better-view');
    }
  }

  closePopup() {
    this.showPopup = false;
  }
  ngOnInit(): void {
    this.showPopup = !this.browserDetectionService.isSupportedBrowser();

    window.scrollTo(0, 0);
    this.modalService
      .getModal()
      .pipe(filter((el) => el.type === 'translate'))
      .subscribe((data) => {
        this.langModal = true;
        this.langText = parseHtml(data.message);
        this.langBtnText = parseHtml(data.action);
      });
    const localizations = environment.localizations as any;
    const languages = Object.keys(localizations).map(
      (el: any) => localizations[el],
    );
    if (!languages.includes(location.pathname.split('/')[1])) {
      const route = location.pathname.split('/');
      route[1] = 'uk';
      location.pathname = route.join('/');
    }
  }

  changeLanguage(): void {
    this.localization.locale = 'uk';
    this.translocoService.setActiveLang('uk' as string);
    location.pathname = location.pathname.replace('en', 'uk');
  }

  back(): void {
    const prevPage = sessionStorage.getItem('prevPage');
    if (prevPage && prevPage !== location.pathname) {
      if (prevPage.includes('uk')) {
        location.pathname = prevPage;
      } else {
        this.router.navigateByUrl(prevPage);
      }
    } else {
      if (!prevPage) {
        this._location.back();
      } else {
        this.router.navigateByUrl('/en');
      }
    }
    this.langModal = false;
  }
}
