<div *ngIf="routes.length > 1" class="bread-crumbs">
  <div
    *ngFor="let route of routes; let index = index"
    class="bread-crumbs__item h10-text"
    [ngClass]="[
      index === 0 ? 'no-padding' : '',
      index === routes.length - 1 ? 'bread-crumbs__item_last' : ''
    ]"
  >
    <ng-container *ngIf="index === 0; else default">
      <div
        class="icon bread-crumbs__item-action"
        [routerLink]="'/' + localization.locale"
        [inlineSVG]="'./assets/images/home.svg'"
      ></div>
    </ng-container>
    <ng-template #default>
      <span class="divider h10-text">/</span>
      <span
        [routerLink]="'/' + getRoute(index)"
        [ngClass]="[
          index === 0 ? '' : 'bread-crumbs__item-action',
          index === routes.length - 1 ? 'bread-crumbs__item-not-action' : ''
        ]"
        >{{ route | transloco }}</span
      >
    </ng-template>
  </div>
</div>
