import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  @Input() questions: any[] = [];
  @Input() loading = false;
  @Input() loadingFaq = false;
  @Input() filters: any[] = [];
  @Input() isFirstActive = false;
  @Input() icon = 'assets/images/about__partners.svg';
  @Input() link = '';
  @Input() subtitle = '';
  @Input() title = '';
  @Input() page = 0;
  @Input() total = 0;

  @Output() moreEmitter = new EventEmitter();
  @Output() hideEmitter = new EventEmitter();
  @Output() categoryEmitter = new EventEmitter();

  filterValue = null;
  constructor() {}

  ngOnInit(): void {}

  addMore() {
    this.moreEmitter.emit();
  }
  hide() {
    this.hideEmitter.emit();
  }

  changeCategory(category: string): void {
    this.categoryEmitter.emit(category);
  }
}
