import { Component, OnInit } from '@angular/core';
import { ItemApiService } from '../../../api/item-api.service';
import { TranslocoService } from '@ngneat/transloco';
import { LoaderService } from '../../../services/loader.service';
import { MedLocalizationService } from '@mk/med-localization';
import { ImageService } from '../../../services/image.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  providers: [ImageService],
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  constructor(
    private itemService: ItemApiService,
    public imgService: ImageService,
    private translocoService: TranslocoService,
    private loaderService: LoaderService,
    public localization: MedLocalizationService,
  ) {
    this.loaderService.isLoadingSubject.next(true);
  }
  aboutMain: any;
  aboutDetail: any;
  lanaguage: any;
  aboutMissons: any;
  aboutInfo: any;
  aboutPartners: any;
  corruptions: any
  aboutValues: any;
  loaded = false;

  prevent(flag: boolean, event: any): void {
    if(!flag) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
  ngOnInit(): void {
    this.lanaguage = this.translocoService.getActiveLang();
    forkJoin([
      this.itemService.getAbout(),
      this.itemService.getCareerAnticorruption(),
    ]).subscribe(
      ([mainData, corruption]) => {
        this.aboutInfo = mainData.aboutInfo;
        this.aboutMain = mainData.aboutMain;
        this.aboutMissons = mainData.aboutMissons;
        this.aboutMain = mainData.aboutMain;
        this.aboutValues = mainData.aboutValues;
        this.corruptions = corruption;
        this.aboutPartners = mainData.aboutPartners;
        this.loaded = true;
        this.loaderService.isLoadingSubject.next(false);
      },
      () => {
        this.loaderService.isLoadingSubject.next(false);
      },
    );
  }
}
