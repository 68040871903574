import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MedLocalizationService } from '@mk/med-localization';
import { BreadCrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss'],
})
export class BreadCrumbsComponent implements OnInit {
  routes: string[] = [];
  constructor(
    public localization: MedLocalizationService,
    public router: Router,
    private breadcrumbService: BreadCrumbService,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const locations = location.pathname.split('/');
        this.routes = locations.filter((el) => el);
        this.routes = this.routes.map((el, index) => {
          if (el === 'search') {
            return 'search.title';
          }
          if (el === 'bi' || el === 'bi_supplier') {
            return 'miss';
          }
          if (el === 'schedule-for-demand-collection') {
            el = 'demand-schedule';
          }
          if (
            !isNaN(+el.split('-')[0]) ||
            (index > 0 && this.routes[index - 1]) === 'catalog'
          ) {
            this.breadcrumbService.getLink().subscribe((link) => {
              this.routes[this.routes.length - 1] = link;
            });
            return '';
          } else {
            return 'menu.' + el;
          }
        });
        if (this.routes.includes('miss')) {
          this.routes = [];
        }
      }
    });
  }

  getRoute(position: number): string {
    return this.routes
      .filter((el, index) => index <= position)
      .map((el) => el.replace('menu.', ''))
      .join('/');
  }
}
