import { Component, OnInit } from '@angular/core';
import {MedLocalizationService} from "@mk/med-localization";
import {ItemApiService} from "../../api/item-api.service";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  lang = location.pathname.split('/')[1];
  constructor(
    private itemService: ItemApiService,
    public localization: MedLocalizationService,
    public translocoService: TranslocoService,
  ) { }
  footer: any;

  ngOnInit(): void {
    this.translocoService.langChanges$.subscribe(()=>{
      this.initFooter();
      setTimeout(()=>{
        this.lang = location.pathname.split('/')[1];
      })
      console.log(this.lang)
    })

  }

  initFooter(): void {
    this.itemService.getFooter().subscribe((data: any[]) => {
      this.footer = data[0];
    })
  }

}
