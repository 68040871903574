import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import {PAGINATION_OPTIONS} from "../../configs/pagination";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent {
  readonly paginationOptions: any[] = PAGINATION_OPTIONS;

  @HostBinding("class") class = "pagination";

  @Input() total = 0;
  @Input() page = 1;
  @Input() limit = 0;

  @Output() pageChange = new EventEmitter<number>();
  @Output() limitChange = new EventEmitter<number>();
}
