<div
  [ngClass]="extraClass"
  class="navblock block disable-up-down-animation-for-min"
>
  <img class="nav-image" imgDirective [src]="img" alt="" />
  <div
    pAnimateOnScroll
    enterClass="fadein"
    leaveClass="fadeout"
    class="navblock__info animation-duration-1000"
  >
    <div class="navblock__title h4-heading">{{ title }}</div>
    <div class="minor-navigation">
      <med-icon-chevron-right [size]="'16px'"></med-icon-chevron-right>
    </div>
    <div *ngIf="description" class="navblock__text text-min">{{ description }}</div>
    <a
      *ngIf="!external"
      class="animation-duration-300 min-link"
      pAnimateOnScroll
      enterClass="fadeindown"
      leaveClass="fadeoutdown"
      [routerLink]="'/' + localization.locale + '/' + navLink"
    >
      <med-btn>
        {{ actionTitle }}
        <med-icon-chevron-right [size]="'16px'"></med-icon-chevron-right>
      </med-btn>
    </a>
    <a
      *ngIf="external"
      class="animation-duration-300 min-link"
      pAnimateOnScroll
      enterClass="fadeindown"
      leaveClass="fadeoutdown"
      [target]="'_blank'"
      [href]="navLink"
    >
      <med-btn>
        {{ actionTitle }}
        <med-icon-chevron-right [size]="'16px'"></med-icon-chevron-right>
      </med-btn>
    </a>
  </div>
</div>
