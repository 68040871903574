import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        const lastPage =   sessionStorage.getItem('lastPage')
        if(lastPage) {
          sessionStorage.setItem('prevPage', lastPage)
        }
        sessionStorage.setItem('lastPage', event.url)
      }
    });
  }
}
