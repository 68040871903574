export const environment = {
  production: false,
  localizationApi: 'https://backend.mpu-dev.meddata.com.ua/api',
  host: 'https://backend.mpu-dev.meddata.com.ua',
  activeLocale: 'uk',
  ignoreLocales: [],
  recaptcha: { siteKey: '6LfoK4ApAAAAAMyixAjAd7Ug2iZvIWPss4SX8cBT' },
  localizationProjectName: '',
  localizations: {
    UA: 'uk',
    EN: 'en',
  },
};
