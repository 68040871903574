import { Component, OnInit } from '@angular/core';
import { menuItems } from '../../consts/menu-items.const';
import { MedLocalizationService } from '@mk/med-localization';
import { MedID, MedOptionType } from '@mk/med-ui';
import { LOCALIZATION_CONFIG } from '../../configs/localization.config';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ItemApiService } from '../../api/item-api.service';
import { delay, filter } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
import { NotificationService } from '../../services/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  readonly locales: MedOptionType<MedID>[] = [];
  constructor(
    public route: ActivatedRoute,
    public localization: MedLocalizationService,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    private itemService: ItemApiService,
    private loaderService: LoaderService,
    private router: Router,
  ) {
    Object.entries(LOCALIZATION_CONFIG.locales).forEach(([label, value]) =>
      this.locales.push({ value, label }),
    );
  }
  viewSize = 0;
  menuItems = [];
  searchForm = new FormGroup({
    search: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(800),
    ]),
  });
  isBetterView = localStorage.getItem('betterView') === 'true';
  isSearch = false;
  isSidebarOpen = false;
  actionsMin = false;
  ngOnInit(): void {
    this.loaderService.isGlobalLoadingSubject.next(true);
    this.initHeaderItems();
    document.addEventListener('click', () => {
      this.isSearch = false;
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.isSidebarOpen = false;
      });
  }

  initHeaderItems() {
    this.itemService
      .getHeaderItems()
      .pipe(delay(400))
      .subscribe(
        (data) => {
          this.loaderService.isGlobalLoadingSubject.next(false);
          this.menuItems = data.map((el: any) => {
            if (el.link === 'meas') {
              el.children[1].link = 'meas/contract-status';
            }
            return el;
          });
        },
        () => {
          this.loaderService.isGlobalLoadingSubject.next(false);
        },
      );
  }

  navigate(event: any): void {
    event.stopImmediatePropagation();
  }

  selectItem(items: any[], item: any) {
    if (item.active) {
      item.active = !item.active;
    } else {
      items = items.map((el) => (el.active = false));
      item.active = !item.active;
    }
  }

  setLocale(lang: MedID): void {
    this.changeRecaptchaLanguage(lang);
    this.localization.locale = lang;
    this.translocoService.setActiveLang(lang as string);
  }

  changeRecaptchaLanguage(language: any) {
    const elem = document.querySelector('#recaptcha iframe');
    if (elem) {
      // Get the current src attribute
      let src = elem.getAttribute('src') as any;
      const hlRegex = /[?&]hl=([a-zA-Z]{2})/;

      // Capture the current value of the hl parameter, if present
      const match = src.match(hlRegex);
      const locale = this.localization.locale as string;
      const currentHl = match ? match[1] : null;
      if (currentHl !== language.replace('ua', 'uk')) {
        location.pathname = location.pathname.replace(locale, language);
      }
      // Update the src attribute with the modified URL
    }
  }
  emitSearch(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.isSearch = true;
  }

  preventDefault(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  searchEmpty(text: any): void {
    if (text !== this.searchForm.get('search')?.value) {
      this.searchForm.get('search')?.patchValue(text);
    }
    if (!text) {
      this.search();
    }
  }

  search(): void {
    if (this.searchForm.valid) {
      this.router.navigateByUrl(
        (this.localization.locale +
          '/search?key=' +
          this.searchForm.get('search')?.value) as string,
      );
      this.isSearch = false;
    } else {
      const errors = this.searchForm.get('search')?.errors;
      if (errors) {
        Object.keys(errors).map((key) => {
          if (key === 'minlength') {
            this.notificationService.error(
              this.translocoService
                .translate('validations.min')
                ?.replace(':min', '') +
                ' ' +
                errors[key].requiredLength,
            );
            return;
          }
          if (key === 'maxlength') {
            this.notificationService.error(
              this.translocoService.translate('validations.max') +
                ' ' +
                errors[key].requiredLength,
            );
            return;
          }
          return;
        });
        return;
      }
    }
  }

  toggleBetterView(): void {
    this.isBetterView = !this.isBetterView;
    localStorage.setItem('betterView', JSON.stringify(this.isBetterView));
    if (this.isBetterView) {
      document.getElementById('body')?.classList.add('better-view');
    } else {
      document.getElementById('body')?.classList.remove('better-view');
      document.getElementById('body')?.classList.remove('large-1');
      document.getElementById('body')?.classList.remove('large-2');
      document.getElementById('body')?.classList.remove('large-3');
      this.viewSize = 0;
    }
  }

  lowerView(): void {
    document.getElementById('body')?.classList.remove('large-' + this.viewSize);
    this.viewSize--;
    document.getElementById('body')?.classList.add('large-' + this.viewSize);
  }
  nextView(): void {
    if (
      document
        .getElementById('body')
        ?.classList.contains('large' + this.viewSize)
    ) {
      document
        .getElementById('body')
        ?.classList.remove('large-' + this.viewSize);
    }
    this.viewSize++;
    document.getElementById('body')?.classList.add('large-' + this.viewSize);
  }
}
