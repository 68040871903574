<div class="faq">
  <div class="faq-title">
    <img class="faq-title__img" [src]="icon" />
    <div class="faq-title__text h2-heading">{{ title }}</div>
    <div
      *ngIf="subtitle"
      class="faq-subtitle text"
      [innerHTML]="subtitle"
    ></div>
  </div>
  <div
    class="faq-questions animation-duration-500"
    pAnimateOnScroll
    enterClass="fadeinrightease"
    leaveClass="fadeoutrightease"
  >
    <med-select
      (ngModelChange)="changeCategory($event)"
      *ngIf="filters.length"
      [(ngModel)]="filterValue"
      [options]="filters"
      class="faq-questions__filter"
    ></med-select>
    <ng-container *ngIf="!loadingFaq && questions.length; else empty">
      <med-accordion
        [active]="index === 0 && isFirstActive"
        *ngFor="let question of questions; let index = index"
        containerClass="faq-question"
        headerClass="faq-question__title text-min"
        contentClass="faq-question__content text-12"
      >
        <div class="faq-question__title-text h9-text" [innerHTML]="question.title">
        </div>
        <ng-container content>
          <div
            pAnimateOnScroll
            enterClass="fadein"
            leaveClass="fadeout"
            class="h9-text animation-duration-500"
            [innerHTML]="question.details "
          >
          </div>
        </ng-container>
      </med-accordion>
      <med-btn
        *ngIf="total && questions.length < total && !loading"
        (click)="addMore()"
        class="normal-padding"
        skin="gray"
        >{{ "faq.more" | transloco }}</med-btn
      >
      <med-btn
        *ngIf="total && questions.length >= total && page > 1 && !loading"
        (click)="hide()"
        class="normal-padding"
        skin="gray"
      >{{ "faq.hide" | transloco }}</med-btn
      >
      <p-progressSpinner
        styleClass="spinner"
        [strokeWidth]="'3px'"
        *ngIf="loading"
      ></p-progressSpinner>

      <a [routerLink]="link">
        <med-btn *ngIf="!page && !total" class="normal-padding">
          {{ "faq.all" | transloco }}
          <med-icon-chevron-right
            [size]="'16px'"
            style="margin-left: 10px"
          ></med-icon-chevron-right>
        </med-btn>
      </a>
    </ng-container>
    <ng-template #empty>
      <med-accordion
        style="opacity: 0"
        containerClass="faq-question"
        headerClass="faq-question__title text-min"
        contentClass="faq-question__content text-12"
      >
        <div class="faq-question__title-text h9-text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium
          aliquam amet animi consequuntur eius ipsum, iusto, magnam maiores
          necessitatibus neque quae quidem repellat reprehenderit sapiente sunt
          temporibus vero voluptas voluptates?
        </div>
        <ng-container content>
          <div
            pAnimateOnScroll
            enterClass="fadein"
            leaveClass="fadeout"
            class="h9-text animation-duration-500"
          ></div>
        </ng-container>
      </med-accordion>
    </ng-template>
    <p-progressSpinner
      *ngIf="loadingFaq"
    >
    </p-progressSpinner>
  </div>
</div>
