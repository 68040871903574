<div class="public">
  <div
    pAnimateOnScroll
    enterClass="fadein"
    leaveClass="fadeout"
    class="public-items animation-duration-200"
  >
    <app-public-item
      [publicItem]="publicItem"
      *ngFor="let publicItem of publicItems"
      class="public-item"
    ></app-public-item>
  </div>
</div>
