import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about.component';
import { AboutRoutingModule } from './about-routing.module';
import {SharedModule} from "../../../shared/shared.module";
import {AnimateOnScrollModule} from "../../../directives/animate-on-scroll.directive";
import {ImageDirectiveModule} from "../../../directives/image.module";

@NgModule({
  declarations: [
    AboutComponent,
  ],
  exports: [AboutComponent],
    imports: [CommonModule, AboutRoutingModule, SharedModule, AnimateOnScrollModule, ImageDirectiveModule],
})
export class AboutModule {
  constructor() {
  }
}
