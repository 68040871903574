import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';
import { ItemApiService } from '../../../api/item-api.service';
import { parseHtml } from '../../../utils/utils';
import { forkJoin } from 'rxjs';
import { MedLocalizationService } from '@mk/med-localization';

@Component({
  selector: 'app-patients',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss'],
})
export class SuppliersComponent implements OnInit {
  supplyItems: any[] = [];
  supplyItemPage = 0;
  supplyTotalPage = 0;
  loaded = false;
  questions: any;
  publicItems: any;
  suppliersMain: any;
  howWeBuy: any;
  tenderProcedures: any;
  whatWeBuy: any;
  constructor(
    private loaderService: LoaderService,
    private itemService: ItemApiService,
    public localization: MedLocalizationService,
  ) {
    this.loaderService.isLoadingSubject.next(true);
  }

  ngOnInit(): void {
    forkJoin(
      this.itemService.getLastNinePublic(),
      this.itemService.getSuppliersFaqTopThree(),
      this.itemService.getSupply(),
    ).subscribe(
      ([publicItems, faq, supplyData]) => {
        this.suppliersMain = supplyData.suppliersMain;
        this.questions = faq;
        this.questions.content = this.filterByPosition(this.questions.content);

        this.publicItems = publicItems;
        this.howWeBuy = supplyData.howWeBuy;
        this.howWeBuy.content = this.filterByPosition(this.howWeBuy.content);
        if (this.howWeBuy?.content?.[1]?.actions?.[0]) {
          this.howWeBuy.content[1].actions[0].link =
            '/' + this.localization.locale + '/prozorro-market';
        }
        this.whatWeBuy = supplyData.whatWeBuy;
        this.tenderProcedures = supplyData.tenderProcedures;

        this.whatWeBuy.details = this.whatWeBuy.description
          ? parseHtml(this.whatWeBuy.description)
          : '';
        this.whatWeBuy.content = this.filterByPosition(this.whatWeBuy.content);

        this.supplyTotalPage = (this.whatWeBuy.content.length - 5) / 5;
        this.addSupply();
        // this.aboutPartners = data.aboutPartners;
        this.loaderService.isLoadingSubject.next(false);
        this.loaded = true;
      },
      () => {
        this.loaderService.isLoadingSubject.next(false);
        this.loaded = true;
      },
    );
  }

  addSupply() {
    this.supplyItems = this.whatWeBuy.content.slice(
      0,
      (this.supplyItemPage + 1) * (this.supplyItemPage ? 5 : 5),
    );
  }
  addSupplies() {
    this.supplyItemPage += 1;
    this.addSupply();
  }

  hide() {
    this.supplyItemPage = 0;
    this.addSupply();
  }

  getTooltipPosition(): string {
    // Determine screen width
    const screenWidth = window.innerWidth;
    // Set tooltip position based on screen width
    if (screenWidth > 900) {
      return 'right'; // Position tooltip to the left
    } else {
      return 'top'; // Position tooltip on top
    }
  }

  scrollToElement(event: any, index: any, $element: any): void {
    if (index === 0) {
      event.stopImmediatePropagation();
      event.preventDefault();
      $element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }

  filterByPosition(arr: any[]): any[] {
    return arr.sort((a, b) => a.position - b.position);
  }
}
