import { Injectable } from '@angular/core';
import {ItemApiService} from "./item-api.service";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: ItemApiService) {
    this.currentUserSubject = new BehaviorSubject<any>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(): void {
    this.http.getUser().subscribe(
      (user:any) => {
        this.currentUserSubject.next(user.token);
        localStorage.setItem('currentUser', JSON.stringify(user));
      },
      (error: any) => {
        console.error('Login failed', error);
      }
    );
  }
}
