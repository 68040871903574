import { APP_ROUTE_KEY } from './APP_ROUTE.key';
import { AppRoute } from '@mk/med-ui';

export const APP_ROUTES: Record<APP_ROUTE_KEY, AppRoute> = {
  [APP_ROUTE_KEY.CONTACTS]: {
    path: 'contacts',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.BI]: {
    path: 'bi',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.BI_SUPPLIER]: {
    path: 'bi_supplier',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.TEAM]: {
    path: 'about/team',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.ERROR]: {
    path: 'error',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.MAIN]: {
    path: '',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.ABOUT]: {
    path: 'about',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.FILIA]: {
    path: 'about/medical-supply-branch',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.REPORTS]: {
    path: 'about/reports',
    data: {
      breadcrumb: {} as any,
      localizationFiles: [
        // { folder: "pages", file: "main" },
      ],
    } as any,
  },

  [APP_ROUTE_KEY.ORDERS]: {
    path: 'about/orders',
    data: {
      breadcrumb: {} as any,
      localizationFiles: [
        // { folder: "pages", file: "main" },
      ],
    } as any,
  },

  [APP_ROUTE_KEY.CAREER]: {
    path: 'about/career',
    data: {
      breadcrumb: {} as any,
      localizationFiles: [
        // { folder: "pages", file: "main" },
      ],
    } as any,
  },

  [APP_ROUTE_KEY.SUPPLIER]: {
    path: 'suppliers',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.HOSPITALS]: {
    path: 'hospitals',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.HOSPITALS_SCHEDULE]: {
    path: 'hospitals/schedule-for-demand-collection',
    data: {
      breadcrumb: {} as any,
    } as any,
  },


  [APP_ROUTE_KEY.PUBLIC]: {
    path: 'suppliers/public',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.PATIENTS]: {
    path: 'patients',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.SUPPLIER_FAQ]: {
    path: 'suppliers/faq',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.PATIENTS_FAQ]: {
    path: 'patients/faq',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.APPLY_REQUEST]: {
    path: 'patients/requests',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.CATALOG]: {
    path: 'patients/catalog',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.MEDIA]: {
    path: 'media-center',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.SEND_CORRUPTION]: {
    path: 'anticorruption/report-corruption',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.ANTICORRUPTION]: {
    path: 'anticorruption',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.PROZORRO]: {
    path: 'prozorro-market',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.PROZORRO_ADD]: {
    path: 'prozorro-market/add-products',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.PUBLIC_ID]: {
    path: 'suppliers/public/:id',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.CAREER_ID]: {
    path: 'about/career/:id',
    data: {
      breadcrumb: {} as any,
    } as any,
  },

  [APP_ROUTE_KEY.MEDIA_ID]: {
    path: 'media-center/:id',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.CATALOG_ID]: {
    path: 'patients/catalog/:id',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.ACTIVE_CATEGORY]: {
    path: 'prozorro-market/categories',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.SEARCH]: {
    path: 'search',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
  [APP_ROUTE_KEY.DKD]: {
    path: 'meas',
    data: {
      breadcrumb: {} as any,
    } as any,
  },
};
