<div *ngIf="footer" class="footer">
  <div class="footer__text h8-text">
    {{ footer.title }}
    <div>
      {{
        lang === "uk"
          ? "За фінансової підтримки PEPFAR та USAID"
          : "Designed and developed with support PEPFAR and USAID"
      }}
    </div>
  </div>

  <div class="footer__links">
    <div class="footer__links-actions">
      <med-btn
        *ngFor="let action of footer.actions"
        class="normal-padding"
        [routerLink]="localization.locale + '/' + action.link"
        [skin]="'gray'"
      >
        {{ action.title }}
      </med-btn>
    </div>
    <div class="footer__links-icons">
      <a
        *ngFor="let link of footer.links"
        [target]="'_blank'"
        [href]="link.link"
      >
        <div
          [ngClass]="link.title === 'Youtube' ? 'youtube' : ''"
          [inlineSVG]="$any(link).img"
        ></div>
      </a>
    </div>
  </div>
</div>
