import { Routes } from "@angular/router";
import { medLocalizeRoutes, MedLocalizationFilePath } from "@mk/med-localization";
import {LOCALIZATION_CONFIG} from "../configs/localization.config";


export class LocalizeRoutes {
  constructor(
    private localizedRoutes: Routes,
    private nonLocalizedRoutes: Routes,
    private files: MedLocalizationFilePath[],
  ) {}

  build(): Routes {
    const localized = medLocalizeRoutes(
      this.localizedRoutes,
      LOCALIZATION_CONFIG.locale,
      LOCALIZATION_CONFIG.locales,
      this.files,
    );
    return localized.concat(this.nonLocalizedRoutes);
  }
}
